@import '../../app-variable';

.lane-management-change-run {
  .slide-out-content h1 {
    margin-right: 5% !important;
  }

  .slide-out-content {
    max-width: 420px;

    .slide-out-body {
      max-height: calc(100% - 6rem) !important;
    }

    .prism-radio>label {
      margin-top: 3px;
      margin-bottom: 3px
    }

    .search-seller-btn {
      align-items: center;
      display: flex;
      margin-top: 1.6em;
    }

    .lane-management-change-run-footer {
      background-color: white;
      position: absolute;
      bottom: 0;
      width: calc(100% - 0.1rem);


      .btn-outline {
        margin-right: 0 !important;
      }

      @media (max-width: 480px) {
        .flex-sm-row-reverse {
            flex-direction: row-reverse !important;
        }
      }
    }

    .control-buttons {
      width: 100%;
      display: flex;
      padding-right: 0.1rem !important;
    }

    .col-form-label {
      font-size: $app-font-size;
    }

    .col-form-label label {
      font-size: $app-font-size;
      color: #6a6a6a;
    }

    .prism-radio input {
      height: 16px !important;
      min-width: 16px !important;
      width: 16px !important;
    }

    .error-message {
      margin: 0 0 0.8rem 0 !important;
    }

    .col-md-1 {
      flex: 0 0 5%;
      max-width: 5%;
    }

    .add-symbol {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}