@import '../../app-variable';

.lane-management-add-run {

  .prism-radio>label {
    margin-top: 3px;
    margin-bottom: 3px
  }

  .search-seller-btn {
    align-items: center;
    display: flex;
    margin-top: 1.6em;
  }

  .lane-management-add-run-footer {
    background-color: var(--white);
    position: absolute;
    bottom: 0;
    width: calc(100% - 0.1rem);
  }

  .control-buttons {
    width: 100%;
    display: flex;
    padding-right: 0.1rem!important;
  }

  .col-form-label {
    font-size: $app-font-size;
  }

  .error-message {
    margin: 0 0 0.8rem 0 !important;
  }
  .slide-out-content {
    max-width: 420px !important;
  }

  @media only screen and (max-width: 1920px) and (max-height: 1080px) {
    .slide-out-content .slide-out-body{
      height: 88%;
    }
  }

  @media only screen and (max-width: 1168px) and (max-height: 800px) {
    .slide-out-content .slide-out-body{
      height: 85%;
    }
  }
  @media (max-width: 480px) {
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
  }
  .slide-out-content h1 {
    margin-right: 5%;
  }
}
