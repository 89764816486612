@import '../../.././app-variable.scss';

.remaining-vehicle-detail {
    background-color: rgba(255, 255, 255, 0.45);
        .vehicle-detail-content {
            .border-top {
                border-top: 1px solid rgba(186, 188, 190, 0.3) !important;
                padding-top: .4rem;
                padding-bottom: .4rem;
            }
            .border-top-vehicle {
                border-top: 1px solid rgba(186, 188, 190, 0.7) !important;
            }
            .text-muted {
                min-width: fit-content;
                color: var(--charcoal) !important;
            }
            p {
                margin-bottom: .1rem !important;
            }
            .row {
                gap: 6px !important;
            }
            padding-right: 1rem;
            .vehicle-attr-floor {
                .form-control {
                    width: 5rem !important;
                }
            }
            .light-indicator {
                box-sizing: border-box;
                display: inline-block;
                width: 24px;
                height: 24px;
                background: var(--smoke);
                border: 1px solid var(--white);
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 100%;
                margin: 3px 5px 0 0;
            }

            .light-indicator-square {
                border-radius: 0
            }

            .icon-search-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .icon-search {
                padding: 8px;
                border: 1px solid #005BA8;
                border-radius: 4px;
                font-weight: 700;
                color: #005BA8;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 28px;
            }

            .seller-number-attr {
                .form-control {
                    width: 10rem !important;
                }
            }

            .announcements-attr {
                .form-control {
                    width: 11rem !important;
                }
            }

            .disposition-attr {
                .form-control {
                    width: 10rem !important;
                }
            }
            .prism-message {
                margin-right: 0.2rem !important;
            }
            .remarks-attr {
                 width: 50% !important;
            }
            .btn-update-wrapper {
                text-align: right;
                bottom: 0;
                right: 0;
                width: 100%;
                white-space: nowrap;
            }
            a:visited {
                color: var(--blue) !important;
            }
            a:hover {
                color: var(--cerulean) !important;
            }
            .font-weight-bold {
                font-weight: 500 !important;
            }
            .vehicle-note-attr {
                max-width: 20% !important;
            }
        }

    @media  only screen and (max-width: 1000px) {
        .vehicle-detail-content {
            .btn {
                font-size: $app-font-size-small-screen !important;
            }
            .light-indicator {
                width: 20px;
                height: 20px;
            }
            .form-control {
                height: $app-from-control-height-small-screen;
                font-size: $app-font-size-small-screen;
            }
        }
    }
    @media only screen and (max-width: 679px) {
    .vehicle-detail-content {
        .col-seller-number {
            padding-right: .8rem;
            }
        .row {
            padding-left: 0 !important;
            }
        }
    }
    @media only screen and (max-width: 499px) {
        .vehicle-detail-content {
            .seller-number-attr, .announcements-attr, .disposition-attr {
                .form-control {
                    width: 8.5rem !important;
                }
            }
        }
    }
}

